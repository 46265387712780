
import jQuery from "jquery";

window.$ = jQuery;
window.jQuery = jQuery;

declare global {
  interface Window {
    $: typeof jQuery;
    jQuery: typeof jQuery;
  }
}

export default jQuery;