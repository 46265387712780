(function() {
  let display_toggle = function() {
    $('[data-radio-toggle] input[type="radio"]').on('change', function(e) {
      let $changeElement = $(e.currentTarget);
      let $toggleWrapperElement = $changeElement.closest('[data-radio-toggle]');
      let toggleValue = $toggleWrapperElement.data('radio-toggle');
      let oppositeToggleValue = $toggleWrapperElement.data('radio-toggle-opposite');
      let $toggleElement = $toggleWrapperElement.find('input[type="radio"][value="' + toggleValue.toString() + '"]');
      let $oppositeToggleElement = oppositeToggleValue && $toggleWrapperElement.find('input[type="radio"][value="' + oppositeToggleValue.toString() + '"]');

      let $target = $($toggleWrapperElement.data('toggle-target'));
      let $oppositeTarget = $($toggleWrapperElement.data('toggle-opposite-target'));
      let $siblingElements = $toggleElement.siblings('input[type="radio"]');
      let noOtherOptionSelected = $siblingElements.filter(":checked").length == 0;
      let hideTargetsBeforeSelection = $toggleWrapperElement.data('hide-targets-before-selection');

      let hideTargets = hideTargetsBeforeSelection && noOtherOptionSelected;

      let toggleDisplayDirection = $toggleWrapperElement.data('display-direction');
      let toggleDisplayDirectionReversed = toggleDisplayDirection === 'reversed'

      if ($toggleElement.is(":checked")) {
        $target.toggleFormSection(!toggleDisplayDirectionReversed)
        if ($oppositeTarget != null) {
          $oppositeTarget.toggleFormSection(toggleDisplayDirectionReversed)
        }
      } else if ($oppositeToggleElement && !$oppositeToggleElement.is(":checked") && !noOtherOptionSelected) {
          $target.hide();
          if ($oppositeTarget != null) { $oppositeTarget.hide() };
      } else {
        if (hideTargets) {
          $target.hide();
          if ($oppositeTarget != null) { $oppositeTarget.hide() };
        } else {
          $target.toggleFormSection(toggleDisplayDirectionReversed)
          if ($oppositeTarget != null) {
            $oppositeTarget.toggleFormSection(!toggleDisplayDirectionReversed)
          }
        }
      }
    }).trigger('change');
  };

  $(display_toggle);
  $(document).on('page:change fields_added.nested_form_fields', display_toggle);
}());
