$(function() {
  let eligibleOptionNo = document.getElementById('participant_eligibility_update_eligible_false')

  if (eligibleOptionNo != null) {
    let willBeEligibleOptionYes = document.getElementById('participant_eligibility_update_will_become_eligible_true')
    let willBeEligibleOptionNo = document.getElementById('participant_eligibility_update_will_become_eligible_false')
    let eligibleOnInput = document.getElementById('participant_eligibility_update_entry_on')

    const disableEligibleOnInput = () => {
      eligibleOnInput.disabled = true
      eligibleOnInput.classList.add('disabled')
      eligibleOnInput.value = ''
    }

    willBeEligibleOptionNo.addEventListener('click', () => {
      disableEligibleOnInput()
    });

    willBeEligibleOptionYes.addEventListener('click', () => {
      eligibleOnInput.disabled = false
      eligibleOnInput.classList.remove('disabled')
    })

    if (eligibleOptionNo.checked == true && willBeEligibleOptionNo.checked == true) {
      disableEligibleOnInput()
    }
  }
});
