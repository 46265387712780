/// <reference types="vite/client" />
import "../src/jquery";
import * as Rails from "@rails/ujs";
import { startStimulusApplication, startTurbo } from "@betterment/b4b";
import { startCacheWorker } from "@betterment/js-runtime";
import * as scLegacyInitializers from "@betterment/style-closet-legacy";

import "../stylesheets/application.scss";
import "../src/behaviors/donut.js";
import "../src/behaviors/links.js";
import "../src/behaviors/participant_eligibility_update.js";
import "../src/behaviors/year_end_utility_use_ach_amount.js";
import "../src/form_behaviors/form_refresh.js";
import "../src/form_behaviors/nested_form_fields_tooltips.js";
import "../src/utils/analytics.js";
import "../vendor/jQuerySpinnerPlugin.js";
import "../vendor/nested-form-fields.js";
import "../../../vendor/gems/b4b_core/app/assets/javascripts/b4b_core/radio_button_display_toggle.js";
import "../../../vendor/gems/b4b_core/app/assets/javascripts/b4b_core/toggle_form_section.js";

import.meta.glob("../images", { eager: true });

if (!window._rails_loaded) {
  Rails.start();
}

for (const initializer in scLegacyInitializers) {
  scLegacyInitializers[initializer]();
}

startTurbo();
startStimulusApplication();
startCacheWorker();
