(function() {
  $('.Donut').ready(function() {
    $('.Donut-slice').on('mouseover', function(e) {
      var sliceId = $(this).data('slice-id'),
          $donut = $(this).closest('.Donut');

      $donut.find('.Donut-holeTextStatic').css('opacity', 0);
      $donut.find(".Donut-tooltipContent[data-slice-id='" + sliceId + "']").css('opacity', 1);
    });

    $('.Donut-slice').on('mouseout', function(e) {
      $('.Donut-tooltipContent').css('opacity', 0);
      $('.Donut-holeTextStatic').css('opacity', 1);
    });
  });
})();
